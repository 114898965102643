$wayops-container: #efefef;
$wayops-container-fade: #dddbdb;
$wayops-container-darker: #cacaca;
$wayops-flag-stroke: #222222;

$wayops-primary: #59C2AD;
$wayops-primary-hover: #49bda6;
$wayops-primary-active: #59C2AD;
$wayops-secondary: #52aa94;
$wayops-dark: #626267;
$wayops-light: #8dfce9;
$wayops-primary-transparent: rgba(255, 220, 20, 0.15);

// Buttons
$wayops-primary-button-text: white;

// Bootstrap variables override
$primary: $wayops-primary;
$dropdown-link-hover-color: $primary;
$dropdown-link-active-bg: $primary;
