@import '@core/scss/base/bootstrap-extended/include'; // Components includes
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~katex/dist/katex.min.css';
@import '@core/scss/base/plugins/forms/form-quill-editor';

.ql-toolbar {
  .ql-formats {
    &:focus,
    *:focus {
      outline: 0;
    }
    .ql-picker-label,
    button {
      &.ql-active {
        .ql-stroke {
          stroke: $primary;
        }
      }
      &:hover {
        color: $primary;
        .ql-stroke {
          stroke: $primary;
        }
      }
    }
  }
}
