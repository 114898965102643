@import '../scss/variables/variables';
@import '../../@core/scss/angular/libs/datatables.component.scss';
@import '../../@core/scss/base/pages/app-user.scss';
@import '../../@core/scss/angular/libs/select.component.scss';
@import '../../@core/scss/angular/libs/date-time-picker.component.scss';

[_nghost-ng-c2511288464] {
  display: block !important;
}

.dark-layout {
  .text-black {
    color: #fff !important;
  }
}

.text-black-70 {
  color: #2F323E;
  font-weight: 700;
}

.text-black-500 {
  color: #000;
  font-weight:500;
}

.text-black {
  color: #000;
  font-weight: 700;
}

::-webkit-scrollbar {display: none;}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
  background: none !important;
  color: #eecd11 !important
}

.dark-layout .main-menu-content .navigation-main .active .menu-title, .dark-layout .main-menu-content .navigation-main .active i, .dark-layout .main-menu-content .navigation-main .active svg {
  color: #eecd11 !important;
}

.main-menu.menu-dark .navigation > li.active > a {
  background: none !important;
  box-shadow: none !important;
}

.main-menu ul.navigation-main > li:first-child {
	margin: 0 !important;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation {
	padding-top: 2rem !important;
}

.dark-layout .main-menu-content .navigation-main {
  background-color: #2E323E !important;
}

.dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
  background: #2E323E !important;
}

.main-menu.menu-dark .navigation > li.open:not(.menu-item-closing) > a, .main-menu.menu-dark .navigation > li.sidebar-group-active > a {
  color: #eecd11 !important;
  background: #2E323E !important;
  border-right: 3px solid;
  margin-right: 0;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content {
  background-color: #2E323E !important;
}

.semi-dark-layout .main-menu-content .navigation-main .nav-item .menu-content li:not(.active) a {
  background-color: #2E323E !important;
}

.main-menu .navbar-header {
  background-color: #2E323E !important;
}

.main-menu .main-menu-content{
  padding-top: 2rem;
  background-color: #2E323E !important;
}


.main-menu.menu-dark .navigation > li ul .active {
  background: none !important;
  box-shadow: none !important;
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu{
  background-color: #2E323E !important;
}

.semi-dark-layout .main-menu-content .navigation-main{
  background-color: #2E323E !important;
}

.btn-flotante {
  position: absolute !important;
}

ngb-modal-backdrop{
  z-index: 1050 !important;
}

.align-left{
  text-align: left;
}

.ql-align-center{
  text-align: center;
}

.ql-align-right{
  text-align: right;
}

.ql-align-justify{
  text-align: justify;
}

.datatable-body-row.datatable-row-odd{
 height: auto !important;
}

.color-icon{
  color: #eecd11 !important;
}

div.ng-value-container{
  max-height: 200px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto; /* Agrega una barra de desplazamiento vertical si es necesario */
}

.name:hover{
  cursor: pointer;
  color: #1ab7ea;
}

.scrollable-container {
  max-height: 100px; /* Set the desired maximum height for the container */
  overflow: auto;    /* Enable scrolling when content exceeds container height */
  border: none;
  padding: 0;
}

.scrollable-input {
  width: 100%;       /* Expand input to container width */
  border: none;      /* Remove default input border */
  outline: none;     /* Remove input focus outline */
}

.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.wr-bw{
  word-wrap: break-word !important;
}

.d-contents{
  display: contents !important;
}

.mb-eye{
  margin-bottom: 3px;
}

.form-control:disabled, .form-control[readonly] {
  background: none !important;
}

.mycheckbox input:checked ~ span::after{
  background: #8db600;
  transform: scale(0.85) translate(35px);
}
.mycheckbox input:checked ~ span .on{
  transform: translate(30px);
  opacity: 1;
}

.mycheckbox input:checked ~ span .off{
  transform: translate(30px);
  opacity: 0;
}

.mycheckbox input:checked ~ span .on-bell{
  transform: translate(30px);
}

.mycheckbox input {
  display: none;
}

.mycheckbox span {
  display: inline-block;
  width: 60px;
  height: 30px;
  border-radius: 30px;
  background: #f8f8f8;
  cursor: pointer;
  box-shadow: inset 0px 0px 2px rgb(15, 15, 15);

  position: relative;
}

.mycheckbox span::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  transform: scale(0.85);
  border-radius: 30px;
  background: rgb(255, 72, 72);
  transition: 0.5s;
  box-shadow: inset 0px 0px 2px rgb(37, 37, 37);
}

.mycheckbox i {
  position: absolute;
  left: 7px;
  top: 7px;
  z-index: 1;
  color: white;
  transition: 0.5s;
}

.mycheckbox .on {
  opacity: 0;
  left: 7px;
  top: 6px;
}

.mycheckbox .off {
  left: 9px;
  top: 7px;
}

.mycheckbox .off-bell {
  left: 9px;
}

#indent { text-indent: 2em !important;  }

.red-error {
  &.ng-invalid.ng-touched .ql-toolbar.ql-snow + .ql-container.ql-snow{  border-color: #F32021 !important;
    &.ql-editor.ql-blank::before{
      color: #A30E09 !important;
    }
  }
}

.ng-select.ng-invalid.ng-touched .ng-select-container{
  border-color: #F32021;
}

.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder{
  color: #A30E09;
}

.red-border-form { 
  border-color: #F32021 !important;
  &::placeholder{
    color: #A30E09 !important;
  }
}

.t-align{
  text-align: justify !important;
}

.customColor{
  color: #6e6b7b !important;
}
.margin {
  margin-left: 5px;
}

.total-pages {
  text-align: right;
  margin-right: 15px;
}

.counterCharacters {
    display: flex;
    justify-content: end;
    font-size: 14px;
    color: #cacaca
}

html {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

body {
    font-size: 16px;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: scroll;
    box-sizing: border-box;
}

.text-requerid{
  color: #f34242;
  font-size: 12px;
}

a {
    color: $wayops-primary;

    &:hover {
        color: $wayops-primary-hover;
    }
}