//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding component variables.
//  ================================================================================================

// Custom variables
// $base-font-size: 15px;

@import "variables";

// Bootstrap styles overwrite
.btn-primary {
  background-color: $wayops-primary !important;
  border-color: $wayops-primary !important;
  color: $wayops-primary-button-text !important;

  &:hover {
    box-shadow: 0 8px 25px -8px $wayops-secondary !important;
  }

  &:active {
    background-color: $wayops-primary-active !important;
    border-color: $wayops-primary !important;
  }

  &:focus {
    background-color: $wayops-primary-active !important;
  }
}

.btn-outline-primary {
  border-color: $wayops-primary !important;
  color: $wayops-primary !important;
}

.bg-light-primary {
  color: $wayops-primary !important;
  background-color: $wayops-primary-transparent !important;
}

.dropdown-toggle:focus,
.dropdown-item:active {
  background-color: $wayops-primary-transparent !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: $wayops-primary !important;
  background-color: $wayops-primary-transparent !important;
}

.btn-flat-primary {
  color: $wayops-primary !important;
}

.text-primary {
  color: #eecd11 !important;
}

.form-control:focus {
  border-color: $wayops-primary;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-cell {
  height: auto !important;
  white-space: normal !important;
  word-break: break-word !important;
  overflow: hidden !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  border-right: 1px solid $wayops-container-darker !important;
}

.ngx-datatable.bootstrap.core-bootstrap
  .datatable-header
  .datatable-header-cell-template-wrap {
  justify-content: center !important;
}

.ngx-datatable.bootstrap.core-bootstrap
  .datatable-header
  .datatable-header-cell {
  border-right: 1px solid $wayops-container-darker !important;
}
